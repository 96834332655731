<template>
    <div class="email_comp">
        <div class="top_area">
            <h3 class="sub_heading2">Email Content</h3>
            <div v-if="hasRecipient" class="form_grp">
                <div class="group_item">
                    <label class="input_label">Email Recipient</label>
                    <div class="field_wpr" :class="{ 'has-error': errors[`recipient_${fieldKey}`]  }">
                        <Field autocomplete="off" type="text" :name="`recipient_${fieldKey}`" placeholder="Recipient" v-model="form.recipient" :rules="{ required: validate }" label="recipient" />
                    </div>
                    <ErrorMessage :name="`recipient_${fieldKey}`" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <div class="input_label">
                        Subject Line
                        <emoji-picker :onSelectEmoji="insertEmoji" classes="subject-emoji-picker" :ref="`emoji-${emailUuid}`" v-click-outside="hideEmoji" />
                        <div class="dropdown mr-3" @click="personalizeOption = !personalizeOption" v-click-outside="hidePersonalizeOption">
                            <i class="fas fa-user-alt"></i>
                            <div class="drp_wrapper" v-if="personalizeOption" :class="{ active : personalizeOption }">
                                <ul>
                                    <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="field_wpr" :class="{ 'has-error': errors[`subject_${fieldKey}`]  }">
                        <Field autocomplete="off" type="text" :name="`subject_${fieldKey}`" placeholder="Type Subject" v-model="form.subject" :id="emailUuid" :rules="{ required: validate }" label="subject" @focusout="handleTrackFocusout('subject')" />
                    </div>
                    <ErrorMessage :name="`subject_${fieldKey}`" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label" v-click-outside="hideMsgOption">
                        Message
                        <div class="dropdown" @click="msgOption = !msgOption">
                            <i class="fas fa-ellipsis-h"></i>
                            <div class="drp_wrapper" v-if="msgOption" :class="{ active: msgOption }">
                                <ul>
                                    <li v-if="hasResetDefault" @click="resetToDefault()">Reset to default message</li>
                                    <!-- <li @click="templateLibrary = !templateLibrary" :class="{ active : templateLibrary }">Import Template</li> -->
                                    <li @click="sendTestEmail = !sendTestEmail" :class="{ active : sendTestEmail }">Send test email</li>
                                    <li @click="previewTestEmail = !previewTestEmail" :class="{ active : previewTestEmail }">Preview in browser</li>
                                </ul>
                            </div>
                        </div>
                    </label>
                    <div class="editorWpr mb-4" :class="{ 'has-error': errors[`email_content_${fieldKey}`] }">
                        <Field autocomplete="off" :name="`email_content_${fieldKey}`" v-model="form.email" :class="{ 'has-error': errors[`email_content_${fieldKey}`] }" :rules="{ required: validate }" label="email content">
                            <redactor v-model="form.email" :default-value="editorDefaultValue" :reset-watcher="resetToDefaultWatcher" :default-value-watcher="editorDefaultValueWatcher" :name="`email_content_${fieldKey}`" :has-import-template="true" :personalize="personalize" :import-template-handler="toggleTemplateDrawer" :track-focusout="trackEmailContentFocusout" />
                        </Field>
                    </div>
                    <ErrorMessage :name="`email_content_${fieldKey}`" class="text-danger" />
                    <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="email" />
                    <send-email-sms-preview v-model="sendTestEmail" :is-preview="previewTestEmail" @closePreview="previewTestEmail = value" :post-params="form" :module="previewModule" :type="1" />
                </div>
            </div>
        </div>
        <div class="additional_area">
            <h3 class="sub_heading2 mb-3">Email Branding</h3>
            <div class="capsule_elm">
                <h5 class="light_text">Show Logo</h5>
                <label :for="`logo-${emailUuid}`" class="switch_option capsule_btn">
                    <input type="checkbox" v-model="form.show_email_logo" :id="`logo-${emailUuid}`" :true-value="1" :false-value="0" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div v-if="form.show_email_logo" class="upload_image">
                <image-library v-model="form.email_logo" image-type="public-logo" upload-text="Logo" ref="email-logo-upload" />
            </div>
            <div class="btn_setting_wpr" v-if="hasEmailButton">
                <h3 class="sub_heading2">Email Button</h3>
                <div class="btn_settings">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Background Color</label>
                            <Field autocomplete="off" name="email_button_bg_color" v-model="form.email_button_bg_color" type="text" label="element color">
                                <color-picker v-model="form.email_button_bg_color" :classes="{ 'has-error': errors.email_button_bg_color }" />
                            </Field>
                            <ErrorMessage name="email_button_bg_color" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Text Color</label>
                            <Field autocomplete="off" name="email_button_text_color" v-model="form.email_button_text_color" type="text" label="element color">
                                <color-picker v-model="form.email_button_text_color" :classes="{ 'has-error': errors.email_button_text_color }" />
                            </Field>
                            <ErrorMessage name="email_button_text_color" class="text-danger" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="capsule_elm pt-2">
                <h5 class="light_text">Show Signature</h5>
                <label :for="`signature-${emailUuid}`" class="switch_option capsule_btn">
                    <input type="checkbox" v-model="form.is_signature" :id="`signature-${emailUuid}`" :true-value="1" :false-value="0" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <div v-if="form.is_signature" class="form_grp">
                <div class="group_item">
                    <label class="input_label">Signature <a class="option" @click="createSignature = !createSignature">Create Signature</a></label>
                    <div class="field_wpr">
                        <multiselect
                            v-model="form.signature_id"
                            :options="signatures"
                            placeholder="Select signature"
                            value-prop="id"
                            label="signature_name"
                            :searchable="true"
                        ></multiselect>
                    </div>
                </div>
            </div>
            <create-signature v-model="createSignature" v-show="form.is_signature" v-if="!isFullView" />
            <add-signature v-model="createSignature" v-show="form.is_signature" v-if="isFullView" />
        </div>
    </div>
    <import-template v-model="importTemplate" :handle-import="handleTemplateImport" />
</template>

<script>
    import { uuid } from 'vue-uuid'
    import { Field, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))
    const CreateSignature = defineAsyncComponent(() => import('@/components/CreateSignature'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))
    const SendEmailSmsPreview = defineAsyncComponent(() => import('@/components/SendEmailSmsPreview'))
    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))
    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
    const ImportTemplate = defineAsyncComponent(() => import('@/components/ImportTemplate'))
    const AddSignature = defineAsyncComponent(() => import('@/pages/admin-setting/components/signature/AddSignature'))

    import Toastr from '@/utils/Toastr'
    import constants from '@/utils/Constant'
    import Multiselect from '@vueform/multiselect'
    import debounce from 'lodash.debounce'

    export default {
        name: "Email Component",

        data () {
            return {
                emailUuid: 'email-'+uuid.v4(),
                schedule: {},
                form: {
                    source: 'contacts',
                    subject: '',
                    email: '',
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                    contact_id: '',
                    email_button_bg_color: '#2c3e50',
                    email_button_text_color: '#FFF',
                },
                defaultPersonalize: [],
                personalizeOption: false,
                sendTestEmail: false,
                previewTestEmail: false,
                msgOption: false,
                createSignature: false,
                templateLibrary: false,
                defaultContent: {},
                editorDefaultValue: '',
                resetToDefaultWatcher: 0,
                loaded: false,
                fieldKey: new Date().getUTCMilliseconds(),
                editorDefaultValueWatcher: 0,
                importTemplate: false,
            };
        },

        props:{
            name: String,
            modelValue: Object,
            contacts: [String, Number],
            errors: [Object, Array],
            placeholder: {
                type: String,
                default: 'Type message',
            },
            personalize: {
                type: [Object, Array],
                default: () => [],
            },
            handleDefaultEmail: Function,
            hasRecipient: {
                type: Boolean,
                default: false,
            },
            hasEmailButton: {
                type: Boolean,
                default: false,
            },
            previewModule: {
                type: String,
                default: 'email',
            },
            isFullView: {
                type: Boolean,
                default: false,
            },
            hasResetDefault: {
                type: Boolean,
                default: true,
            },
            validate: {
                type: Boolean,
                default: true,
            },
            trackFocusout: Function,
        },

        emits: ['update:modelValue', 'update:email'],

        components: {
            Multiselect,
            TemplateLibrary,
            CreateSignature,
            ImageLibrary,
            Field,
            ErrorMessage,
            SendEmailSmsPreview,
            EmojiPicker,
            ColorPicker,
            AddSignature,
            ImportTemplate,
        },

        watch: {
            form: {
                handler (form) {
                    const vm = this;
                    vm.$emit('update:modelValue', form);
                },
                deep: true
            },

            modelValue: {
                handler (form) {
                    const vm = this;

                    if (form.email && !vm.loaded) {
                        vm.editorDefaultValue = form.email;

                        setTimeout(function () {
                            vm.editorDefaultValueWatcher += 1;
                            vm.loaded = true;
                        }, 1000);
                    }

                    if (form.defaultEmail) {
                        vm.form.subject = form.subject;
                        vm.form.email = form.email;
                    }

                    setTimeout(function () {
                        vm.loaded = true;
                    }, 500);
                },
                deep: true
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            signatures: state => state.signatureModule.signatures,
            signatureLoader: state => state.signatureModule.signatureLoader,
        }),

        mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
            vm.defaultContent     = vm.modelValue;

            if (vm.signatures.length == 0 && !vm.signatureLoader) {
                vm.getSignature();
            }

            vm.resetToDefault(true);
        },

        methods:{
            ...mapActions({
                getSignature: 'signatureModule/getSignature',
            }),

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById(vm.emailUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.form.subject = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById(vm.emailUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.form.subject = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            resetToDefault (mounted = false) {
                const vm = this;

                if (!mounted && vm.defaultContent.defaultHandler) {
                    vm.loaded = false;
                    vm.handleDefaultEmail();
                } else if (vm.defaultContent.email) {
                    vm.form = JSON.parse(JSON.stringify(vm.defaultContent));
                    vm.form.email_logo = vm.form.email_logo ? vm.form.email_logo : vm.user.logo;

                    if (vm.hasEmailButton) {
                        vm.form.email_button_bg_color = vm.modelValue.email_button_bg_color ? vm.modelValue.email_button_bg_color : '#2c3e50';
                        vm.form.email_button_text_color = vm.modelValue.email_button_text_color ? vm.modelValue.email_button_text_color : '#FFF';
                    }

                    vm.resetToDefaultWatcher += 1;
                } else {
                    vm.form = {
                        source: 'contacts',
                        subject: '',
                        email: '',
                        is_email_conf: 0,
                        is_email_logo: 0,
                        is_signature: 0,
                        signature_id: 0,
                        show_email_logo: 0,
                        email_logo: vm.user.logo,
                        contact_id: vm.contacts,
                        email_button_bg_color: '#2c3e50',
                        email_button_text_color: '#FFF',
                    };
                }
            },

            handleTemplateImport (template) {
                const vm = this;

                vm.form.subject = template.subject;
                vm.form.email   = template.email_content;
                vm.editorDefaultValue = template.email_content;
                vm.resetToDefaultWatcher += 1;
            },

            hidePersonalizeOption () {
                const vm = this;

                vm.personalizeOption = false;
            },

            hideMsgOption () {
                const vm = this;

                vm.msgOption = false;
            },

            hideEmoji () {
                const vm = this;
                const emoji = vm.$refs[`emoji-${vm.emailUuid}`];

                if (emoji && emoji.toggle) {
                    emoji.toggle = false;
                }
            },

            toggleTemplateDrawer () {
                const vm = this;

                vm.importTemplate = true;
            },

            trackEmailContentFocusout: debounce(function () {
                const vm    = this;

                if (vm.trackFocusout) {
                    vm.trackFocusout('content')
                }
            }, 500),

            handleTrackFocusout (type) {
                const vm = this;

                if (vm.trackFocusout) {
                    vm.trackFocusout(type);
                }
            },
        },
    }
</script>

<style scoped>
    .top_area, .additional_area{
        padding: 20px 30px;
        border-radius: 5px;
        margin-bottom: 15px;
        background: #fff;
    }
    .modal .email_comp .top_area, .modal .email_comp .additional_area{
        padding: 15px 20px;
    }
    .upload_image :deep(.upload_wpr .upload_area){
        background: #f9f9f9;
    }
    .upload_image :deep(.preview_area .upload_wpr .upload_area){
        background: #fff;
    }
    .btn_setting_wpr{
        margin: 15px 0;
    }
    .btn_settings{
        margin-top: 10px;
        padding: 10px 20px 15px 20px;
        border-radius: 5px;
        /* border: 1px solid #e9e9e9; */
        background: #f9f9f9;
    }
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
    }

    :deep(.emoji-picker.subject-emoji-picker) {
        margin: 0 0 0 auto;
    }

    .input_label {
        position: relative;
        z-index: 3;
    }

    :deep(.subject-emoji-picker) {
        position: absolute;
        right: 0;
    }

    :deep(.subject-emoji-picker .dropdown-toggle) {
        margin-right: 40px;
    }
</style>
